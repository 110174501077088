import React, { useContext } from "react";
import TrackContext from "../lib/trackContext";
import { graphql } from "gatsby";
import { format, parseISO } from "date-fns";
import SongShowPage from "../components/SongShowPage";
import { SongProps } from "../lib/";

const formatData = (props: any = {}, context: any = {}): SongProps => {
  return {
    ...props.songs,
    authors: props.songs.authors.join(", "),
    publish_date: props.songs.publish_date || "Unknown",
    recordings: (props.songs.recordings || []).map((recording) => {
      return {
        ...recording,
        dateStr: format(
          parseISO(recording.performance.date),
          "EEEE, LLL. d, yyyy"
        ),
        locationStr: `${recording.performance.location.address.locality}, ${recording.performance.location.address.region}`,
        venueStr: recording.performance.location.name,
        isPlaying:
          context.data.id && recording.id && recording.id === context.data.id,
        performanceSlug: recording.performance.slug,
        source: `Source: ${recording.source}`,
        onPlayClick: () => {
          context.setTrackContext({
            id: recording.id,
            collection: "songs",
          });
        },
      };
    }),
  };
};

export default (props) => {
  const context = useContext(TrackContext);
  return (
    <SongShowPage
      {...formatData(props.data, context)}
      location={props.location}
      onSongPlay={(id) => context.setTrackContext({ id, collection: "songs" })}
    />
  );
};

export const query = graphql`
  query($slug: String!) {
    songs(slug: { eq: $slug }) {
      html
      title
      authors
      publish_date(formatString: "Y-MM-DD")
      recordings {
        id
        source
        performance {
          slug
          id
          date(formatString: "YYYY-MM-DD")
          location {
            name
            address {
              region
              locality
            }
          }
        }
      }
    }
  }
`;
