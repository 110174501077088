import React from "react";
import { SongShowPageProps } from "../lib";
import PageLayout from "./PageLayout";

export default ({
  onSongPlay,
  title,
  authors,
  recordings,
}: SongShowPageProps) => {
  return (
    <PageLayout>
      <article>
        <h1>{title}</h1>
        <h2>Written By: {authors}</h2>
        <h2>
          {recordings.length} Available Recording
          {recordings.length > 1 ? "s" : ""}
        </h2>
        <ul>
          {recordings.map(
            ({
              id,
              performance,
              dateStr,
              locationStr,
              isPlaying,
              performanceSlug,
              source,
              venueStr,
            }) => (
              <li key={id}>
                {isPlaying ? (
                  <span>[Playing]</span>
                ) : (
                  <a href="#" onClick={() => onSongPlay(id)}>
                    [Play]
                  </a>
                )}
                &nbsp;&nbsp;&nbsp;
                <a href={performance.slug}>
                  {dateStr}: {venueStr} in {locationStr}
                </a>
              </li>
            )
          )}
        </ul>
      </article>
    </PageLayout>
  );
};
